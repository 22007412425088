(function ($) {
    'use strict';

    // Side bar menu
    $(function () {
        const $sidebar = $('#sidebar'), $html = $('html');
        const DEVICE_MIN_WIDTH = "768"; // From bootstrap 4/3 break point
        var width;


        $('#sidebar-toggle').on('click', function () {
            var $self = $(this),
                $drawer = $($self.data('target')),
                toggleClasses = $drawer.data('classes'),
                $mainCol = $($drawer.data('targetOther')), classes;

            $drawer.toggleClass('active');

            if ($('html').hasClass('touch')) {

            }
        });


        $('.touch #sidebar').on('click', function (e) {
            $sidebar.addClass('active');
        });

        // $('#deactive-sidebar').click(function () {
        //     $('#sidebar').removeClass('active');
        // });

        $(window).resize(function () {
            width = document.documentElement.clientWidth;
            if (width <= DEVICE_MIN_WIDTH) {
                $sidebar.removeClass('active');
            } else {
                $sidebar.addClass('active');
            }
        });

        if ($html.hasClass('no-touch')) {
            width = document.documentElement.clientWidth;
            if (width < DEVICE_MIN_WIDTH) {
                $sidebar.removeClass('active');
            }
        }

        if ($html.hasClass('touch') && ($html.hasClass('xxsmallview') || $html.hasClass('xsmallview'))) {

            $sidebar.removeClass('active');
        }

    });

})(jQuery);