/*************************************************
 Star Rating System
 First Version: 21 November, 2006
 Author: Ritesh Agrawal
 Augmented by: James Hansen
 Augmented Date: Feb 18, 2021
 Inspriation: Will Stuckey's star rating system (http://sandbox.wilstuckey.com/$-ratings/)
 Demonstration: http://php.scripts.psu.edu/rja171/widgets/rating.php
 ]
 Usage: $('#rating').rating('www.url.to.post.com', {maxvalue:5, curvalue:0});
 NOTE: Must be wrapped in a form element.

 arguments - some can be set on the element's data-{option} attribute
 url : required -- post changes to
 options
 maxvalue: number of stars
 curvalue: number of selected stars
 setRating: function to set the format rating.
 onSucess: Called then the rating has been posted


 ************************************************/
(function ($) {

    $.fn.rating = function (url, options) {

        this.each(function () {
            var container = $(this), div, title, stars, cancel, onSuccess;
            var settings = {
                el: container,
                curvalue: 0, // number of selected stars
                rounding: 0, // Used for rounding external scoring
                titles: [],
                post: false,
                cancel: false,
                other_query_params: '',

                getForm: function () {
                    return container.closest('form');
                },

                // Called when a rating value is selected
                setRating: function (value) {

                },
                // Called on the clicking of the vote ui
                onclick: function (e) {
                },
                // Called after ajax form submissions.
                onSuccess: function (response) {
                } // called after rating is posted.
            };

            $.extend(settings, $.fn.rating.defaults, options, container.data());

            onSuccess = function (response) {
                settings.onSuccess(response);
            };

            for (var i = 0; i <= settings.maxvalue; i++) {
                if (i == 0) {
                    if (settings.cancel == true) {
                        div = '<div class="cancel"><a href="#0" title="Cancel Rating" role="button">Cancel Rating</a></div>';
                        container.append(div);
                    }
                } else {
                    title = (settings.titles.length > i) ? settings.titles[i] : '';
                    div = '<div class="star"><a data-vote="' + i + '" href="' + i + '" title="' + i + '/' + settings.maxvalue + '" role="button"><span class="pv-icon-rate-outline pv-icon text-black"></span></a></div>';
                    container.append(div);
                }
            }

            stars = $(container).children('.star');
            cancel = $(container).children('.cancel');

            stars
                .mouseover(function () {
                    event.drain();
                    event.fill(this);
                })
                .mouseout(function () {
                    event.drain();
                    event.reset();
                })
                .focus(function () {
                    event.drain();
                    event.fill(this);
                })
                .blur(function () {
                    event.drain();
                    event.reset();
                });

            stars.find('a')
                .focus(function () {
                    $(this).closest('.star').focus();
                })
                .blur(function () {
                    $(this).closest('.star').blur();
                });

            // Voting event
            function action(e) {
                var data;

                if (settings.onclick) {
                    settings.onclick(e);
                }

                if (settings.maxvalue > 1) {
                    settings.curvalue = stars.index(this) + 1;
                    // Set rating
                    settings.setRating(settings.curvalue);

                    if (settings.post) {
                        data = settings.getForm().serialize();
                        $.post(url, data + settings.other_query_params, onSuccess, 'json');
                    }

                    return false;
                } else if (settings.maxvalue == 1) { // Cancel should be enable, like rating; current not used.

                    settings.curvalue = (settings.curvalue == 0) ? 1 : 0;

                    $(this).toggleClass('on');

                    if (settings.post) {
                        // data = (settings.formId) ? $(settings.formId).serialize() : {"rating": $(this).children('a')[0].href.split('#')[1]};
                        data = settings.getForm().serialize();
                        $.post(url, data + settings.other_query_params, onSuccess, 'json');
                    }


                    return false;
                }


                return true;
            }

            // Initialize Voting event
            stars.on('click', action);

            // cancel button events
            if (cancel) {
                cancel
                    .mouseover(function () {
                        event.drain();
                        $(this).addClass('on');
                    })
                    .mouseout(function () {
                        event.reset();
                        $(this).removeClass('on');
                    })
                    .focus(function () {
                        event.drain();
                        $(this).addClass('on');
                    })
                    .blur(function () {
                        event.reset();
                        $(this).removeClass('on');
                    });

                // Cancel Vote event.
                cancel.click(function () {
                    event.drain();
                    settings.curvalue = 0;
                    try {
                        settings.setRating(settings.curvalue);
                        if (settings.post) {
                            var data = settings.getForm().serialize();

                            $.post(url, data + settings.other_query_params, settings.onSuccess, 'json');
                        }
                    } catch (e) {
                    }

                    return false;
                });
            }

            var event = {
                fill: function (el) { // fill to the current mouse position.
                    var index = stars.index(el) + 1;
                    stars.children('a').end().slice(0, index).addClass('hover').end();
                },
                drain: function () { // drain all the stars.
                    stars.filter('.on').removeClass('on').end().filter('.hover').removeClass('hover').end();
                },
                reset: function () { // Reset the stars to the default index.
                    stars.slice(0, settings.curvalue).addClass('on').end();
                }
            };

            event.reset();

            return (this);
        });
    };


    $.fn.rating.defaults = {
        maxvalue: 5,
    };

}(jQuery));