(function ($, window) {

    window.reLayout = function (){
        Publivate.$grid.masonry('layout');
    };



    var Comments = {

        initComment: function (comment, reLayout) {
            // Initial any lightbox
            // console.log(comment.find('.wb-lbx'))

            // Must reset lightbox attrs to reuse elements
            comment.find('.wb-lbx').attr('id', null)
                .removeClass('wb-init wb-lbx-inited');

            comment.find('.wb-lbx').trigger('wb-init.wb-lbx');

            // Initial any editing comment
            Comments.initEditCommentForm(comment.find('.idea-edit-comment-submit-action'), reLayout);
            Comments.initThumbsup(comment.find('.idea-comment-like-form'));

            // Initial any reply and editing reply
            Comments.initEditReplyForm((comment.find('.idea-comment-edit-reply-form')));
            Comments.initReplyForm(comment.find('.idea-comment-reply-form-action'));
        },

        /**
         * Initialize the comment form
         * @param el
         */
        initCommentForm: function (el) {
            var $el = $(el);
            $el.each(function () {
                var $form = $(this);


                $form.submit(function (e) {
                    e.preventDefault();

                    if (!Comments.validCommentForm($form)) {
                        return;
                    }

                    $.post($form.data("action"), $form.serialize(), function (response) {
                        if (response.success) {
                            var comment = $(response.html);
                            comment.insertAfter($form.closest('.new-comment').first());
                            Comments.initComment(comment);
                            $form.find("textarea").val("");
                        }
                    }); // End post
                }); // End submit
            });
        },

        /**
         * valdiate a comment or reply form
         * @param form
         * @return {boolean}
         */
        validCommentForm: function (form) {
            var text = form.find('textarea');
            return trim(text.val()).length > 0;
        },

        /**
         * Initialize the edit comment form
         * @param forms
         */
        initEditCommentForm: function (forms) {
            $(forms).each(function () {
                var $form = $(this);

                $form.submit(function (e) {
                    e.preventDefault();

                    if (!Comments.validCommentForm($form)) {
                        return;
                    }

                    $form.closest('.single-comment').find(".text").first().find('span').text($form.find('textarea').val());

                    $($form.data('target')).slideToggle({
                        progress: function(){
                            reLayout();
                        }
                    });

                    $.post($form.data("action"), $form.serialize(), function (response) {

                    });
                });
            });
        },

        /**
         * Initialize the thumbsup/like submission
         * @param forms
         */
        initThumbsup: function (forms) {
            forms.each(function () {
                var $form = $(this), comment_id = $form.find('input[name="comment_id"]').val(),
                    counter = $('#improvement-like-score-' + comment_id);
                var value = counter.text().indexOf(1);
                counter.text('(' + (value + 1) + ')');

                $form.submit(function (e) {
                    e.preventDefault();
                    $form.find('button').attr('disabled', true);
                    $form.closest('.single-comment').find('.like-hide').show();
                    $form.remove();


                    $.post('index.php?lang=' + Publivate.lng, $form.serialize(), function (response) {
                        $('#improvement-like-score-' + comment_id).html('(' + parseInt(response.likes) + ')');
                    }, 'json');
                });
            });
        },

        /**
         * Initialize the fetch more comments for an idea
         * @param comments
         */
        initMoreComments: function (comments) {
            comments = $(comments);

            comments.each(function () {
                var $moreBtn = $(this);
                $moreBtn.click(function () {
                    var parent;
                    var data = {"idea_id": $moreBtn.data('id'), "limitstart": $moreBtn.data('limitstart')};

                    $moreBtn.html('<img class="loader" src="/images/clientadmin/wait30trans.gif" alt="">');

                    $.get('index.php?option=com_publivateideamodule&controller=comments&format=json&task=moreComments', data, function (response) {
                        if (response.success) {
                            var $comments = $(response.data.html);
                            $comments.appendTo($moreBtn.parent());
                            reLayout();
                            Comments.initComment($comments);

                            if (response.data.more_comments == 1) {
                                parent = $moreBtn.parent();
                                $moreBtn.detach().appendTo(parent).show();
                                $moreBtn.data('limitstart', response.data.limitstart).html(Joomla.JText._('LOAD_MORE_IMPROVEMENTS'));
                            } else {
                                $moreBtn.addClass("hide");
                            }
                        } else {
                            $moreBtn.text("unable to load more");
                            $moreBtn.removeClass("hide");
                        }
                    });
                });
            });
        },

        /**
         * Initializing the more replies request
         * @param replies
         */
        initMoreReplies: function (replies) {
            replies.each(function () {
                var $btn = $(this),
                    data = {"comment_id": $btn.data('id'), "limitstart": $btn.data('limitstart')};

                $btn.click(function (e) {
                    $btn.html('<img class="loader" src="/images/clientadmin/wait30trans.gif" alt="">');

                    $.get('index.php?option=com_publivateideamodule&controller=comments&format=json&task=moreReplies', data, function (response) {
                        if (response.success) {
                            var replies_box = $btn.parent(), parent, $replies = $(response.data.html);

                            $replies.appendTo(replies_box);
                            reLayout();

                            if (response.data.more_replies == 1) {
                                $btn.html(Joomla.JText._('LOAD_MORE_REPLIES'));
                                parent = $btn.parent();
                                $btn.detach().appendTo(parent).data('limitstart'.response.data.limitstart);
                            } else {
                                $btn.remove();
                            }

                        } else {
                            $btn.removeClass("hide");
                        }
                    });
                });
            });
        },

        /**
         * Initialize the reply form.
         * @param jQuery forms
         */
        initReplyForm: function (forms) {
            forms.each(function () {
                var $form = $(this);

                $form.submit(function (e) {
                    e.preventDefault();

                    if (!Comments.validCommentForm($form)) {
                        return;
                    }

                    $.post($form.data('action'), $form.serialize(), function (response) {
                        if (response.success) {
                            /* @var repliesEl - Replies section element */
                            var reply = $(response.html), repliesEl = $form.closest('.replies-section'), totalReplies;


                            $(reply).insertAfter($(repliesEl).find(".new-reply").first());
                            Comments.initComment(reply);

                            totalReplies = repliesEl.find('.single-reply').length;

                            if (totalReplies == 1) {
                                $(repliesEl).parent().find(".after-text span.reply-trigger").first().text("1 " + Joomla.JText._('REPLY'));
                            } else {
                                $(repliesEl).parent().find(".after-text span.reply-trigger").first().text(Joomla.JText.sprintf('REPLIES', totalReplies));
                            }

                            $form.find("textarea").val("");
                        }
                    });
                });
            });
        },

        initEditReplyForm: function (forms) {
            forms.each(function () {
                var $form = $(this);

                $form.submit(function (e) {
                    e.preventDefault();

                    if (!Comments.validCommentForm($form)) {
                        return;
                    }

                    $form.closest('.single-reply').find(".text p").first().text($form.find('textarea').val());

                    $($form.data('target')).collapse('toggle');

                    $.post($form.data("action"), $form.serialize(), function (response) {

                    });
                });
            });
        },

    };

    window.PVComments = Comments;
}(jQuery, window));